import React from 'react';
import '../../App.css';
import Cards from '../Cards';

export default function Programs() {
  return (
    <>
      <div className="programs">
          <Cards/>
      </div>
    </>
  )
}

